import React, { useState, useEffect } from "react";
import { dynamicSort } from "../logic/HelperFunctions";
import { doc, getDocs, collection, getDoc } from "firebase/firestore";
import {
  Badge,
  Button,
  Card,
  CardSection,
  Group,
  Image,
  Text,
} from "@mantine/core";
import { PostCard } from "../cards/PostCard";

export const ContentDisplay = (props) => {
  const [posts, setPosts] = useState([]);
  const [pinnedPosts, setPinnedPosts] = useState([]);
  const [hasReplacedPinnedDummys, setReplacedPinnedDummys] = useState(false);
  const [pinnedPostsOverflow, setPinnedPostsOverflow] = useState(false);
  const [half, setHalf] = useState(0);
  const [hasReplacedDummys, setReplacedDummys] = useState(false);

  var postsProxy = [];

  //Either load the first page
  async function GetFirstPosts() {
    setReplacedDummys(true);
    // New to old is set from the dashboard in this case
    // REENABLE LATER
    // const newToOld = !props.metadataDoc.categorySorts[props.category];
    const newToOld = false;

    // This might look confusing but the category has its own presorted top-level map in firestore
    const ref = await getDocs(collection(props.db, String(props.category)));
    console.log("found docs for esction", ref.docs);
    if (ref.docs) postsProxy.push(...ref.docs);

    postsProxy.sort(
      dynamicSort(newToOld ? "-creationDateAsNumber" : "creationDateAsNumber")
    );

    // Split in half so we can have a top and bottom row
    setHalf(Math.ceil(postsProxy.length / 2));

    setPosts(postsProxy);
  }

  async function GetPins() {
    var all = [];
    // FIrst load general pinned posts for this category
    var query = props.db.where(
      "categories." + String(props.category),
      "==",
      true
    );
    query = query.where("isPinned", "==", true);
    var result = await getDoc(query);
    console.log("len base pins", result.docs.length);
    if (result.docs) {
      all = all.concat(result.docs);
    }

    //Then load posts only pinned for specific categories
    var query = props.db.where("isPinCat", "==", true);
    query = query.where("pincats", "array-contains", String(props.category));
    result = await query.get();
    console.log("len specific pins", result.docs.length);
    if (result.docs) {
      all = all.concat(result.docs);
    }

    const newToOld = !props.maxBatchDoc.categorySorts[props.category];
    all.sort(
      dynamicSort(newToOld ? "-creationDateAsNumber" : "creationDateAsNumber")
    );

    console.log("final pins", all);

    if (all.length > 2 && props.user) setPinnedPostsOverflow(true);
    setPinnedPosts(all);

    setReplacedPinnedDummys(true);
  }

  useEffect(() => {
    // if (!hasReplacedPinnedDummys && props.maxBatchDoc) GetPins();
    // if (
    //   !hasReplacedDummys
    //   // &&hasReplacedPinnedDummys
    //   // && props.maxBatchDoc
    // )
    GetFirstPosts();
    console.log("getting posts");
  }, [
    hasReplacedPinnedDummys,
    // props.metadataDoc
  ]);

  const CustomCard = props.customCard;
  return (
    <div
      className={`w-full flex flex-col items-center justify-center ${
        props.topLine && "border-t"
      }`}
    >
      {props.title && (
        <div className="w-full flex justify-start">
          <h3 className=" mt-2 font-base text-3xl">{props.title}</h3>
        </div>
      )}
      <div
        className={`z-0 w-full justify-between max-w-6xl lg:-mx-10 grid grid-cols-1 gap-8  xl:grid-cols-2 ${props.gridCss}`}
      >
        {posts &&
          posts.map((post, idx) => {
            return props.customCard ? (
              <CustomCard
                analytics={props.analytics}
                post={post}
                delay={idx}
                key={idx}
                postImgCss={props.postImgCss}
                addCss={props.addCss}
                postTextCss={props.postTextCss}
              ></CustomCard>
            ) : (
              <PostCard
                analytics={props.analytics}
                post={post}
                delay={idx}
                key={idx}
                postImgCss={props.postImgCss}
                addCss={props.addCss}
                postTextCss={props.postTextCss}
              ></PostCard>
            );
          })}
      </div>
      {props.line && (
        <div className="w-4/5 mx-auto md:w-full h-0 border-t my-10  md:my-20"></div>
      )}
    </div>
  );
};
