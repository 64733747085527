import React from "react";
import { FaPeopleArrows } from "react-icons/fa";
import { GiEarthAmerica, GiFactory, GiFarmTractor } from "react-icons/gi";
import { Fade } from "react-reveal";
import { IKImage } from "imagekitio-react";

export const Info = (props) => {
  return (
    <div className="mt-10 md:mt-0 flex flex-col gap-4 md:flex-row md:gap-32 justify-between p-2 md:p-10">
      {/* <Fade left> */}
      <IKImage
        path={"/idea.jpg"}
        className="rounded-xl mx-auto w-4/5 md:w-2/5 shadow-lg object-cover"
        loading="lazy"
        lqip={{ active: true }}
      ></IKImage>
      {/* </Fade> */}
      <div className="md:w-3/5 w-full">
        <Fade top>
          <h2 className=" mx-auto w-40 md:w-auto flex gap-4 font-bold text-4xl md:text-6xl text-transparent bg-clip-text bg-gradient-to-br from-hard-yellow to-yellow-600">
            About Us
          </h2>
        </Fade>
        <Fade right>
          <ul className="grid grid-cols-9 gap-4 text-gray-700 items-center p-2 align-center">
            <div className="flex justify-center">
              <GiFarmTractor className="col-span-1" size={50}></GiFarmTractor>
            </div>
            <li className="col-span-8">We supply goods and services</li>
            <div className="flex justify-center">
              <GiFactory className="col-span-1" size={45}></GiFactory>
            </div>
            <li className="col-span-8">
              Provided by a renowned team of international business specialists.
            </li>
            <div className="flex justify-center">
              <FaPeopleArrows className="col-span-1" size={40}></FaPeopleArrows>
            </div>
            <li className="col-span-8">
              Great numbers of global partners in international: logistics,
              distribution, customs clearance and development of worldwide
              markets.
            </li>
            <div className="flex justify-center">
              <GiEarthAmerica className="col-span-1" size={45}></GiEarthAmerica>
            </div>
            <li className="col-span-8">
              Flow of the productive chain as well as the social development of
              those who live off the land, or rather on Mother Earth.
            </li>
          </ul>{" "}
        </Fade>
      </div>
    </div>
  );
};
