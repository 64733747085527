import React from "react";
import { BsDistributeHorizontal } from "react-icons/bs";
import { FaPeopleArrows, FaPeopleCarry } from "react-icons/fa";
import {
  GiEarthAmerica,
  GiFactory,
  GiFarmTractor,
  GiOfficeChair,
} from "react-icons/gi";
import { Fade } from "react-reveal";
import {
  Businessplan,
  LayoutDistributeHorizontal,
  Map,
  Truck,
} from "tabler-icons-react";

import { IKImage } from "imagekitio-react";

export const Services = (props) => {
  return (
    <div className="mt-10 md:mt-0 flex flex-col gap-4 md:flex-row md:gap-32 justify-between p-2 md:p-10">
      {/* <Fade left> */}
      <IKImage
        path={"/terminal.jpg"}
        className="rounded-xl mx-auto w-4/5 md:w-2/5 shadow-lg object-cover"
        loading="lazy"
        lqip={{ active: true }}
      ></IKImage>
      {/* </Fade> */}
      <div className="md:w-3/5 w-full">
        <Fade top>
          <h2 className=" mx-auto w-40 md:w-auto flex gap-4 font-bold text-4xl md:text-6xl text-transparent bg-clip-text bg-gradient-to-br from-hard-yellow to-yellow-600">
            Services
          </h2>
        </Fade>
        <Fade right>
          <ul className="grid grid-cols-9 gap-4 text-gray-700 items-center p-2 align-center">
            <div className="flex justify-center">
              <Map className="col-span-1" size={50}></Map>
            </div>
            <li className="col-span-8">
              <p className="font-bold text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-yellow-500">
                Broker:
              </p>{" "}
              Provide assistance to suppliers in Brazil and from Abroad.
              Feasibility studies. Development of new business, optimize sales
              process , client assistance, supplier backup, acceptance and
              approval of goods ( The Ministry of Agriculture ).
            </li>
            <div className="flex justify-center">
              <Truck className="col-span-1" size={45}></Truck>
            </div>
            <li className="col-span-8">
              <p className="font-bold text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-yellow-500">
                {" "}
                Trader:
              </p>{" "}
              Enable “Door to Door“ model to Mid-Sized buyers.
            </li>
            <div className="flex justify-center">
              <FaPeopleCarry className="col-span-1" size={40}></FaPeopleCarry>
            </div>
            <li className="col-span-8">
              <p className="font-bold text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-yellow-500">
                Distributor:
              </p>{" "}
              Act as importer and distributor of goods to small-sized clients,
              realizing the goods and their distribution to Brazilian and
              foreign customers.
            </li>
          </ul>
        </Fade>
      </div>
    </div>
  );
};
