import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import logo from "./logo.svg";
import * as Scroll from "react-scroll";
import {
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import {
  BrandLinkedin,
  BrandWhatsapp,
  Mail,
  Phone,
  Search,
} from "tabler-icons-react";
import { BeautyButton } from "./CMS/buttons/BeautyButton";
import { MultiButton } from "./CMS/buttons/MultiButton";
import { Burger, Kbd } from "@mantine/core";
import { openSpotlight } from "@mantine/spotlight";
// comment
export const NavBar = (props) => {
  const [opened, setOpened] = useState(false);
  const title = opened ? "Close navigation" : "Open navigation";

  const handleKeyPress = (event) => {
    // check if the Shift key is pressed
    if (
      (event.metaKey === true || event.ctrlKey === true) &&
      event.key === "k"
    ) {
      console.log(`Key pressed: ${event.key} ${props.search}`);

      props.setSearch(!props.search);
    }
  };

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handleKeyPress);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  return (
    <div
      className={`absolute color-gray-900 w-full z-40 overflow-x-clip top-0 ${
        !props.standalone && "mt-28"
      } `}
    >
      <Burger
        opened={opened}
        onClick={() => setOpened((o) => !o)}
        title={title}
        size={32}
        // color={"gray"}
        className="z-50 rounded-xl backdrop-blur text-lg absolute top-4 md:hidden left-4 "
      />
      {!props.noCall && (
        <BeautyButton
          onClick={() => logEvent(props.analytics, "Phone Click")}
          hideAnim
          addCss="md:hidden absolute  top-4 right-4 text-xl"
          text={
            <>
              <Phone className="group-hover:rotate-45  transition-all duration-500"></Phone>
              Call Us{" "}
            </>
          }
        ></BeautyButton>
      )}
      <div
        className={`absolute z-40 top-0 md:top-4 bg-white md:bg-transparent w-full h-screen md:h-auto  justify-center flex flex-col  md:relative  ${
          !opened && "-translate-y-full  md:translate-y-0"
        } transition-all duration-500`}
      >
        <nav
          className={`items-center md:flex z-40  h-full md:h-auto  md:flex-row justify-center md:justify-between  px-8 font-bold text-xl `}
        >
          <Link
            to="/home/home"
            className="flex flex-col justify-center"
            duration={500}
          >
            <img src={logo} className="w-40  hidden md:block"></img>
          </Link>
          <div className="flex items-center h-4/5 flex-col md:h-auto md:flex-row gap-8 justify-around md:my-0 my-20 ">
            <Link
              onClick={() => {
                logEvent(props.analytics, "Home Click");
                console.log("want home");
              }}
              className="md:my-auto mx-auto"
              to="/home/home"
              duration={500}
            >
              <p className="cursor-pointer hover:text-gray-700 hover:border-b hover:-translate-y-1 transition-all duration-200 md:hidden text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-yellow-500">
                Home
              </p>
            </Link>
            <Link
              onClick={() => logEvent(props.analytics, "About Click")}
              className="md:my-auto mx-auto"
              to="/home/about"
              duration={500}
            >
              <p className="cursor-pointer hover:text-gray-700 hover:border-b hover:-translate-y-1 transition-all duration-200 text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-yellow-500">
                About
              </p>
            </Link>
            <Link
              onClick={() => logEvent(props.analytics, "Specialty Click")}
              className="md:my-auto mx-auto"
              to="/home/specialties"
              duration={500}
            >
              <p className="cursor-pointer hover:text-gray-700 hover:border-b hover:-translate-y-1 transition-all duration-200 text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-yellow-500">
                Specialties
              </p>
            </Link>
            <Link
              onClick={() => logEvent(props.analytics, "Commodity Click")}
              className="md:my-auto mx-auto"
              to="/home/commodities"
              duration={500}
            >
              <p className="cursor-pointer hover:text-gray-700 hover:border-b hover:-translate-y-1 transition-all duration-200 text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-yellow-500">
                Commodities
              </p>
            </Link>
            <Link
              onClick={() => logEvent(props.analytics, "Services Click")}
              className="md:my-auto mx-auto"
              to="/home/services"
              duration={500}
            >
              <p className="cursor-pointer hover:text-gray-700 hover:border-b hover:-translate-y-1 transition-all duration-200 text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-yellow-500">
                Services
              </p>
            </Link>
            <BeautyButton
              onClick={() => {
                logEvent(props.analytics, "Phone Click");
                window.open("tel:+55 11 98222-2105");
              }}
              hideAnim
              text={
                <>
                  <Phone className="group-hover:rotate-45  transition-all duration-500"></Phone>
                  <span className="md:w-0 w-48 md:overflow-clip md:group-hover:w-48 md:overflow-hidden md:truncate md:line-clamp-1  whitespace-nowrap md:invisible md:group-hover:visible transition-all duration-500 md:opacity-0 md:group-hover:opacity-100">
                    +55 11 982222105
                  </span>
                </>
              }
            ></BeautyButton>
            <MultiButton
              buttons={[
                {
                  onClick: () => {
                    logEvent(props.analytics, "App Click");
                    window.open("https://wa.me/5511982222105");
                  },
                  text: <BrandWhatsapp></BrandWhatsapp>,
                  css: "",
                },
                {
                  onClick: () => {
                    logEvent(props.analytics, "Mail Click");
                    window.open("mailto:saulo@unimixbrasil.com.br");
                  },
                  text: <Mail></Mail>,
                  css: "",
                },
                {
                  onClick: () => logEvent(props.analytics, "LinkedIn Click"),
                  text: <BrandLinkedin></BrandLinkedin>,
                  css: "",
                },
              ]}
            ></MultiButton>
            <Link to="/home">
              <BeautyButton
                onClick={() => {
                  props.setSearch(true);
                }}
                hideAnim
                bgStyling={
                  "backdrop-blur-2xl hover:backdrop-blur-sm bg-transparent hover:bg-transparent border-2 transition-all duration-500 group text-gray-500"
                }
                text={
                  <>
                    <Search className="group-hover:rotate-45 transition-all duration-500"></Search>
                    <span className="block md:hidden mx-2">Search...</span>
                    {window.navigator.platform === "MacIntel" ? (
                      <Kbd className="shadow group-hover:shadow-none group-hover:translate-y-1 transition-all duration-500">
                        ⌘
                      </Kbd>
                    ) : (
                      <Kbd className="shadow group-hover:shadow-none group-hover:translate-y-1 transition-all duration-500">
                        ctrl
                      </Kbd>
                    )}
                    +
                    <Kbd className="shadow group-hover:shadow-none group-hover:translate-y-1 transition-all duration-500">
                      k
                    </Kbd>
                  </>
                }
              ></BeautyButton>
            </Link>
          </div>
        </nav>
      </div>
    </div>
  );
};
