import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  SearchBox,
  Hits,
  Highlight,
  RefinementList,
  Menu,
  SortBy,
} from "react-instantsearch-hooks-web";
import { stripHtml } from "../logic/HelperFunctions";
import "./CustomAlgoliaStyles.css";
import { useSearchBox } from "react-instantsearch-hooks-web";
import { Search } from "tabler-icons-react";
import { PostCard } from "../cards/PostCard";
import { Flip } from "react-reveal";
import { logEvent } from "firebase/analytics";
import { useHistory } from "react-router-dom";
import { useClickOutside } from "@mantine/hooks";
import { CloseButton } from "@mantine/core";

const searchClient = algoliasearch(
  "FKF01UAKEQ",
  "4fc8d6719307952d12e4a2403d8f845e"
);

export const InstantSearchSpotlight = (props) => {
  const history = useHistory();
  const ref = useClickOutside(() => props.setSearch(false));

  function Hit({ hit }) {
    return (
      <article
        onClick={() => {
          logEvent(props.analytics, "Search Clicked", { title: hit.title });
          history.push("/posts/" + encodeURI(hit.title));
        }}
        className={`flex gap-4 h-28 py-2 md:w-140  group cursor-pointer justify-between  transition-all duration-500 whitespace-normal border-b px-2 md:px-6 ${props.addCss}`}
        key={hit.id}
      >
        <img
          className="h-full w-24 object-cover  transition-all duration-500 group-hover:shadow-xl rounded-full"
          src={hit.img}
          alt={hit.title}
        />
        <div>
          <h3 className=" h-8 transition-all duration-500 group-hover:bg-gradient-to-bl  font-extrabold z-50 text-xl my-auto whitespace-normal text-gray-900 md:w-96  ">
            {hit.title}
            <Highlight attribute="title" hit={hit}></Highlight>
          </h3>
          <p className="text-gray-500 md:w-96 line-clamp-2 md:line-clamp-3">
            <Highlight attribute="clean_content" hit={hit} />
          </p>
        </div>
      </article>
    );
  }

  return (
    <div className="z-50 absolute cursor-pointer top-0 h-screen w-screen md:backdrop-blur flex flex-col justify-center">
      <div
        className="mx-auto shadow-lg bg-white max-w-xl rounded-xl h-full md:h-3/4"
        ref={ref}
      >
        <CloseButton
          size={32}
          className="absolute md:hidden z-50 right-4 h-14"
          onClick={() => props.setSearch(false)}
        ></CloseButton>
        <InstantSearch searchClient={searchClient} indexName="docs">
          <SearchBox
            className="border-b text-lg"
            placeholder="Search..."
            autoFocus={true}
          />

          <div className="overflow-y-scroll bg-white rounded-xl  md:w-140">
            <Hits hitComponent={Hit} />
          </div>
        </InstantSearch>
      </div>
    </div>
  );
};
