import { Button, Card, CardSection, Image, Text } from "@mantine/core";
import { logEvent } from "firebase/analytics";
import React from "react";
import { Fade, Flip } from "react-reveal";
import { useHistory } from "react-router-dom";
import {
  SemiRandomColor,
  SemiRandomTWColor,
  stripHtml,
} from "../logic/HelperFunctions";

export const PostCard = (props) => {
  const history = useHistory();

  const data = props.post.data();
  return (
    <Flip bottom delay={props.delay * 300}>
      <div
        onClick={() => {
          logEvent(props.analytics, "Post Clicked", { title: data.title });
          history.push("/posts/" + encodeURI(data.title));
        }}
        className={`z-0 flex mt-8 gap-4 md:gap-8 h-32 mx-auto xl:mx-0 items-center h-40 w-full md:h-40 px-2 md:px-0 md:w-140    group cursor-pointer md:justify-between justify-start rounded-xl  transition-all duration-500 whitespace-normal ${props.addCss}`}
        key={props.post.id}
      >
        <img
          className={`h-24  md:h-36 w-24 md:w-36 object-cover  transition-all duration-500 group-hover:shadow-xl ${
            props.postImgCss && props.postImgCss
          }`}
          src={
            // Check if the source was absolute or relative and chagen accordingly
            data.img
          }
          alt={data.title}
        />
        <div className="w-2/3">
          <h3
            className={`transition-all duration-500 group-hover:bg-gradient-to-bl  font-extrabold z-50 text-xl md:text-3xl my-auto whitespace-normal  md:w-96  text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-yellow-500 break-words ${props.postTextCss}`}
          >
            {data.title}
          </h3>
          <p className={`gap-2 flex flex-col md:w-96 ${props.postTextCss}`}>
            {data.useBullets
              ? data.bullets.map((bullet, idx) => (
                  <span className="flex gap-2 items-center">
                    <div
                      className={`${SemiRandomTWColor(
                        idx * 3
                      )} text-transparent flex  items-center justify-center bg-gradient-to-br to-green-300 h-7 w-7 rounded-full `}
                    >
                      <img
                        className={`h-5 `}
                        src={
                          data.bulletIcons[idx] &&
                          data.bulletIcons[idx].includes("http")
                            ? data.bulletIcons[idx]
                            : process.env.PUBLIC_URL + data.bulletIcons[idx]
                        }
                      ></img>
                    </div>
                    <p className="text-md text-gray-500"> {bullet}</p>
                  </span>
                ))
              : data.summary ||
                (data.description &&
                  stripHtml(data.description).substring(0, 100))}
          </p>
          <a className="text-blue-500 mt-2">Read more...</a>
        </div>
      </div>
    </Flip>
  );
};
