import React from "react";

export const MultiButton = (props) => {
  return (
    <div className="bg-gradient-to-r h-11 from-green-500 to-blue-700  group flex  items-center justify-center font-bold text-white rounded-full shadow transition-all duration-500 ">
      {props.buttons &&
        props.buttons.map((content, idx) => {
          return (
            <div
              key={idx}
              className={`h-full w-full py-1 px-2 flex ${content.css} ${
                idx === 0 && "rounded-l-full border-r pl-4"
              } ${idx === 2 && "rounded-r-full border-l pr-4"}`}
            >
              <button
                className={`hover:border-b hover:-translate-y-1 transition-all duration-500`}
                onClick={content.onClick}
              >
                {content.text}
              </button>
            </div>
          );
        })}
    </div>
  );
};
