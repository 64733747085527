import logo from "./logo.svg";
import "./App.css";
import React, { Suspense, lazy, useState, useEffect } from "react";
import { IKContext } from "imagekitio-react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//Import firebase
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getFirestore, doc, onSnapshot } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";

//
import { Home } from "./pages/Home";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { HeatMap } from "./HEATMAPPER/HeatMap";
import { NavBar } from "./NavBar";

const Dashboard = React.lazy(() => import("./CMS/pages/Dashboard"));
const Editor = React.lazy(() => import("./CMS/pages/Editor"));
const Post = React.lazy(() => import("./CMS/pages/Post"));
const More = React.lazy(() => import("./CMS/pages/More"));

// Pages

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBqOl-kzP2uKQQoUONBMxXu54J1Y6yZvg0",
  authDomain: "unimix-f211f.firebaseapp.com",
  projectId: "unimix-f211f",
  storageBucket: "unimix-f211f.appspot.com",
  messagingSenderId: "210382497625",
  appId: "1:210382497625:web:a637aab2f80c7d97b08e48",
  measurementId: "G-BTRSHJE1R1",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth();
const functions = getFunctions(app);
// connectFunctionsEmulator(functions, "localhost", 5001);

function App() {
  // Keep track of the current logged in user
  const [user, setUser] = useState();
  const [metadata, setMetaData] = useState();
  const [loadedMetadata, setLoadedMetadata] = useState(false);
  onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) {
      setUser(currentUser);
    }
  });

  // Track whenever the user leaves what page
  useEffect(() => {
    window.addEventListener("beforeunload", () => {
      logEvent(analytics, "Leaving app from " + document.title);
    });
    const userStats = { referrer: document.referrer };
  });

  // Not tested
  useEffect(() => {
    if (!loadedMetadata) {
      setLoadedMetadata(true);
      const onSnap = onSnapshot(doc(db, "metadata", "metadata"), (docu) => {
        console.log("Current data: ", docu.data());
        setMetaData(docu.data());
      });
    }
  }, []);

  // Boolean that the user of the CMS can build himself to send to the dashboard
  const [isValidMail, setIsValidMail] = useState(false);

  useEffect(() => {
    if (
      user &&
      (user.email.endsWith("@unimixbrasil.com.br") ||
        user.email.endsWith("@accette.eu") ||
        user.email === "saskuatz@gmail.com")
    ) {
      setIsValidMail(true);
    }
  }, [user]);

  return (
    <Router>
      <MantineProvider>
        <NotificationsProvider>
          <IKContext urlEndpoint="https://ik.imagekit.io/cis/unimix">
            {" "}
            {/* <HeatMap functions={functions} db={db}> */}
            <Suspense fallback={<div>Loading...</div>}>
              <Switch>
                {" "}
                <Route
                  path="/dashboard/:succes?"
                  render={(props) => (
                    <Dashboard
                      {...props}
                      metadata={metadata}
                      db={db}
                      user={user}
                      signInSuccessUrl="dashboard"
                      firebase={app}
                      auth={auth}
                      functions={functions}
                      analytics={analytics}
                      isValidMail={isValidMail}
                      navbar={
                        <NavBar
                          addCss={"bg-slate-50"}
                          noCall
                          standalone
                          analytics={analytics}
                        ></NavBar>
                      }
                    />
                  )}
                />
                <Route
                  path="/editpost/:name"
                  render={(props) => (
                    <Editor
                      {...props}
                      metadata={metadata}
                      db={db}
                      user={user}
                      isValidMail={isValidMail}
                      firebase={app}
                      auth={auth}
                      // For old functions which expect imgs not in imgs/ folder
                      replaceBucketImgs={"unimix"}
                      functions={functions}
                      analytics={analytics}
                      app={app}
                      maxPostsInSection={20}
                      algoliaClient="FKF01UAKEQ"
                      algoliaSecret="4fc8d6719307952d12e4a2403d8f845e"
                      navbar={
                        <NavBar
                          addCss={"bg-slate-50"}
                          noCall
                          standalone
                          analytics={analytics}
                        ></NavBar>
                      }
                    />
                  )}
                />
                <Route
                  path="/more/:categories?/:tags?/:authors?/:publicists?"
                  render={(props) => (
                    <More
                      {...props}
                      metadata={metadata}
                      db={db}
                      user={user}
                      firebase={app}
                      auth={auth}
                      functions={functions}
                      analytics={analytics}
                      navbar={
                        <NavBar
                          addCss={"bg-slate-50"}
                          noCall
                          standalone
                          analytics={analytics}
                        ></NavBar>
                      }
                    />
                  )}
                />
                <Route
                  path="/posts/:name"
                  render={(props) => (
                    <Post
                      {...props}
                      metadata={metadata}
                      db={db}
                      user={user}
                      firebase={app}
                      auth={auth}
                      analytics={analytics}
                      navbar={
                        <NavBar
                          addCss={"bg-slate-50"}
                          noCall
                          standalone
                          analytics={analytics}
                        ></NavBar>
                      }
                    />
                  )}
                />
                <Route
                  path="/home/:section?"
                  render={(props) => (
                    <Home
                      {...props}
                      metadata={metadata}
                      db={db}
                      user={user}
                      firebase={app}
                      auth={auth}
                      analytics={analytics}
                      functions={functions}
                    />
                  )}
                />
                <Route
                  path="/:section?"
                  render={(props) => (
                    <Home
                      {...props}
                      metadata={metadata}
                      db={db}
                      user={user}
                      firebase={app}
                      auth={auth}
                      analytics={analytics}
                      functions={functions}
                    />
                  )}
                />
                <Route
                  path="/?"
                  render={(props) => (
                    <Home
                      {...props}
                      metadata={metadata}
                      db={db}
                      user={user}
                      firebase={app}
                      auth={auth}
                      analytics={analytics}
                      functions={functions}
                    />
                  )}
                />
              </Switch>
            </Suspense>
            {/* </HeatMap>{" "} */}
          </IKContext>
        </NotificationsProvider>
      </MantineProvider>
    </Router>
  );
}

export default App;
