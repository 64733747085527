import { Button, Kbd } from "@mantine/core";
import { logEvent } from "firebase/analytics";
import { IKImage } from "imagekitio-react";
import React from "react";
import { Search } from "tabler-icons-react";
import { BeautyButton } from "./CMS/buttons/BeautyButton";
// https://images.pexels.com/photos/96715/pexels-photo-96715.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260
export const Hero = (props) => {
  return (
    <>
      <div className="100vw h-42 md:h-140 rounded-b-3xl ">
        {/* <img
          src="https://images.unsplash.com/photo-1490093158370-1a6be674437b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2214&q=80"
          className=" h-96 object-cover absolute"
        ></img> */}

        <IKImage
          path={"/river.jpg"}
          className="w-full rounded-xl object-cover h-full -mt-28"
          loading="lazy"
          lqip={{ active: true }}
        ></IKImage>
        {/* <img
          src={glass}
          className="absolute -mt-32  w-64"
          // style={{ filter: "blur(1px)" }}
        ></img> */}
        <div className="-mt-14 md:-mt-34 sm:-mt-28  flex justify-center align-center">
          <h1 className="text-7xl sm:text-9xl md:text-10xl font-extrabold flex">
            <p className="text-slate-200">UNI</p>
            <p className="text-transparent bg-clip-text bg-gradient-to-br from-hard-yellow to-yellow-600">
              MIX
            </p>
          </h1>
        </div>
      </div>
    </>
  );
};
