import React from "react";
import { Flip } from "react-reveal";

export const Introduction = (props) => {
  return (
    <div
      className={`z-0 mx-auto max-w-screen md:max-w-3xl p-4 text-center mt-4 mb-8 md:mt-10 md:mb-20 ${
        props.addCss
      } ${props.noMarginTop && "mt-0"}`}
    >
      <Flip top>
        <h2 className="mx-auto font-bold text-4xl md:text-6xl ">
          {props.title}
        </h2>
      </Flip>
      <Flip bottom>
        <p className="text-gray-700 mt-10">{props.text}</p>{" "}
      </Flip>
      {props.content}

      {props.line && <div className="w-full h-0 border-t mt-10"></div>}
    </div>
  );
};
