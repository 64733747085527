import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import { Hero } from "../Hero";
import { NavBar } from "../NavBar";
import { ProductScroll } from "../ProductScroll";
import { Introduction } from "../Introduction";
import * as Scroll from "react-scroll";
import { Element, scroller } from "react-scroll";
import { Link } from "react-router-dom";

import { Info } from "../pre_build/Info";
import { Services } from "../pre_build/Services";

import { InstantSearchSpotlight } from "../CMS/instantsearch/InstantSearchSpotlight";
import { ContentDisplay } from "../CMS/show_content/ContentDisplay";

import { Input, Loader, TextInput } from "@mantine/core";
import { At, Mail, Phone } from "tabler-icons-react";
import {
  GiExplosiveMaterials,
  GiHeartInside,
  GiStairsGoal,
  GiSupersonicArrow,
} from "react-icons/gi";
import { FaIndustry } from "react-icons/fa";
import { BsPersonPlus } from "react-icons/bs";
import { BeautyButton } from "../CMS/buttons/BeautyButton";
import { logEvent } from "firebase/analytics";
import { doc, setDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

export const Home = (props) => {
  const [search, setSearch] = useState(false);
  const [submit, setSubmit] = useState("Submit");
  const [currentMail, setCurrentMail] = useState();
  const [mailLoad, setMailLoad] = useState(false);
  //Scroll to section
  useEffect(() => {
    const section = props.match.params.section;
    if (section) {
      scroller.scrollTo(section, {
        delay: 400,
        duration: 1000,
      });
    }
  });

  const noScrollStyle = `html, body {
  overflow: hidden;
    -webkit-overflow-scrolling: touch;

}`;

  return (
    <div className="bg-slate-50">
      {" "}
      <Helmet>
        <title>Unimix</title>
        <meta
          name="description"
          content="We supply goods and services
Provided by a renowned team of international business specialists.
Great numbers of global partners in international: logistics, distribution, customs clearance and development of worldwide markets.
Flow of the productive chain as well as the social development of those who live off the land, or rather on Mother Earth."
        />
      </Helmet>
      <NavBar
        analytics={props.analytics}
        setSearch={setSearch}
        search={search}
        standalone
      ></NavBar>
      <Element id="home">
        <Hero analytics={props.analytics}></Hero>
      </Element>
      {search && (
        <InstantSearchSpotlight
          analytics={props.analytics}
          setSearch={setSearch}
        ></InstantSearchSpotlight>
      )}
      {search && <style>{noScrollStyle}</style>}
      <div className="md:p-20">
        <Element id="about">
          <Info></Info>
        </Element>
        <Element id="specialties">
          <Introduction
            title="Specialties"
            text="Unimix specializes in providing unique high-value products directly to business. "
            addCss={"md:mb-0"}
          ></Introduction>
          <ContentDisplay
            db={props.db}
            category="Specialties"
            metadata={props.metadata}
            analytics={props.analytics}
            postImgCss="rounded-full"
            line
          ></ContentDisplay>
        </Element>
        <Element id="commodities">
          <Introduction
            title="Commodities"
            text="Unimix specializes in providing unique high-value products directly to business. "
            addCss={"md:mb-0"}
          ></Introduction>
          <ContentDisplay
            db={props.db}
            category="Commodities"
            metadata={props.metadata}
            analytics={props.analytics}
            postImgCss="rounded-full"
            line
          ></ContentDisplay>
        </Element>
        <Element id="services">
          <Services></Services>
        </Element>
        <Introduction
          title="Join the Insiders List"
          addCss="-mb-8 md:mb-0"
        ></Introduction>
        <div className="flex md:flex-row flex-col justify-center items-center gap-4 ">
          <ul className="grid  w-80 grid-cols-9 gap-4 text-gray-700 items-center p-2 align-center">
            <div className="flex justify-center">
              <GiSupersonicArrow
                className="col-span-1"
                size={50}
              ></GiSupersonicArrow>
            </div>
            <li className="col-span-8">
              <p className="font-bold text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-yellow-500">
                Monthly update by one of our experts
              </p>{" "}
            </li>
            <div className="flex justify-center">
              <GiStairsGoal className="col-span-1" size={45}></GiStairsGoal>
            </div>
            <li className="col-span-8">
              <p className="font-bold text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-yellow-500">
                {" "}
                Inside expert opinions that you and we care about
              </p>{" "}
            </li>
            <div className="flex justify-center">
              <GiHeartInside className="col-span-1" size={40}></GiHeartInside>
            </div>
            <li className="col-span-8">
              <p className="font-bold text-transparent bg-clip-text bg-gradient-to-br from-orange-600 to-yellow-500">
                No spam
              </p>{" "}
            </li>
          </ul>
          <div className="flex flex-row items-end mb-10">
            <TextInput
              icon={<At />}
              label="Your Mail"
              placeholder="john@doe.com"
              className="w-80 "
              onChange={(e) => setCurrentMail(e.target.value)}
            ></TextInput>
            <BeautyButton
              onClick={async () => {
                setMailLoad(true);
                await setDoc(
                  doc(props.db, "metadata", "mails"),
                  {
                    [currentMail || "wasemptymail"]: true,
                  },
                  { merge: true }
                );
                var func = httpsCallable(props.functions, "AddUserToMailchimp");

                func({ email: currentMail, audiences: ["9a428a086d"] })
                  .then((result) => {
                    // Read result of the Cloud Function.
                    console.log(result);
                    setSubmit("✅ Succesfully submitted");
                    setMailLoad(false);
                  })
                  .catch((error) => {
                    console.log(error);
                    setSubmit("❌ " + error);
                  });
              }}
              text={
                <>
                  {mailLoad && <Loader></Loader>} {submit}
                </>
              }
              addCss={`-ml-24 h-9 -mb-1 z-50 ${
                submit === "✅ Succesfully submitted" &&
                "bg-gray-300 bg-none hover:bg-none cursor-default"
              }`}
            ></BeautyButton>
          </div>
        </div>
      </div>
      {/* <DesignerSection></DesignerSection> */}
      <div className="w-full py-8 md:py-0 md:h-40 bg-slate-800 flex justify-around flex-col gap-8 md:gap-0 md:flex-row">
        <div className="flex flex-col md:w-96 justify-center items-center gap-4 text-sm">
          {" "}
          <p className="text-xl font-bold text-white text-lg">CONTACT</p>
          <div
            className="flex gap-2 text-white cursor-pointer group hover:text-green-500"
            onClick={() => {
              logEvent(props.analytics, "Phone Click");
              window.open("tel:+55 11 98222-2105");
            }}
          >
            <Phone></Phone>
            <p className="text-lg font-bold text-white group-hover:text-green-500">
              +55 11 982222105
            </p>
          </div>
          <div
            className="flex gap-2 text-white cursor-pointer group hover:text-green-500 text-sm"
            onClick={() => {
              logEvent(props.analytics, "Phone Click");
              window.open("mailto:saulo@unimixbrasil.com.br");
            }}
          >
            <Mail></Mail>
            <p className="text-lg font-bold text-white group-hover:text-green-500">
              saulo at unimixbrasil.com.br
            </p>
          </div>
        </div>
        <div className="flex flex-col md:w-96 justify-center items-center gap-4">
          {" "}
          <p className="text-xl font-bold text-white">UNIMIX</p>
          <Link
            to="/dashboard"
            className="text-green-400 font-bold cursor-pointer border-b border-green-400 hover:border-green-200 hover:text-green-100 transition-all"
          >
            Dashboard
          </Link>
        </div>
        <div className="flex flex-col md:w-96 justify-center items-center gap-4 text-sm">
          {" "}
          <p className="text-xl font-bold text-white">DESIGN</p>
          <p
            className="text-lg font-bold text-white hover:text-green-400 cursor-pointer"
            onClick={() => {
              logEvent(props.analytics, "Phone Click");
              window.open("https://pitchai.net/");
            }}
          >
            Pitchai.net
          </p>
        </div>
      </div>
    </div>
  );
};
